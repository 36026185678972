.gridcontainer {
    display: grid;
    grid-template:
        ". . ." 1fr
        " . coverA ." auto
        ". titleA ." auto
        ". swipeLeftA ." auto
        ". . ." 1fr / 1fr auto 1fr;
    width: 100vw;
    height: 100vh;
}

.title {
    margin-top: 4.875rem;
    margin-bottom: 2.1rem;
    grid-area: titleA;
    font-family: "Fredoka One", cursive;
    font-size: 3rem;
    line-height: 151.5%;
    text-align: center;
    letter-spacing: normal;
}

.swipeLeft {
    grid-area: swipeLeftA;
    text-align: center;
}
.cover {
    grid-area: coverA;
}
.mia {
    color: #943131;
}

.gridcontainer {
    display: grid;
    grid-template:
        ". . ." 1fr
        ". coverA ." auto
        ". . ." 7rem
        ". textA ." auto
        ". . ." 4.735rem
        ". soundOnA ." auto
        ". . ." 1fr / 1fr auto 1fr;
    width: 100vw;
    height: 100vh;
    justify-items: center;
}

.cover {
    grid-area: coverA;
    animation: animation 5s infinite;
}

.soundOn {
    grid-area: soundOnA;
    text-align: center;
    font-size: 1rem;
}

@keyframes animation {
    0%,
    20%,
    40%,
    50% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(30deg);
    }
    30% {
        transform: rotate(-30deg);
    }
    75% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.text {
    grid-area: textA;
    max-width: 15rem;
    text-align: center;
    font-size: 1rem;
}

@media (min-width: 600px) {
    .text {
        max-width: none;
    }
}

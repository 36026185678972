@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);

* {
    margin: 0;
    padding: 0;
}

html {
    font-family: "Montserrat", sans-serif;
    background: #ded9ff;
    color: #4e4c5b;
    line-height: 240%;
    letter-spacing: 0.15em;
    overflow-x: hidden;
}

.Home_gridcontainer__m4__N {
    display: grid;
    grid-template:
        ". . ." 1fr
        " . coverA ." auto
        ". titleA ." auto
        ". swipeLeftA ." auto
        ". . ." 1fr / 1fr auto 1fr;
    width: 100vw;
    height: 100vh;
}

.Home_title__3h164 {
    margin-top: 4.875rem;
    margin-bottom: 2.1rem;
    grid-area: titleA;
    font-family: "Fredoka One", cursive;
    font-size: 3rem;
    line-height: 151.5%;
    text-align: center;
    letter-spacing: normal;
}

.Home_swipeLeft__3bEwC {
    grid-area: swipeLeftA;
    text-align: center;
}
.Home_cover__3-oWl {
    grid-area: coverA;
}
.Home_mia__1rD2H {
    color: #943131;
}

.MePage_gridcontainer__2IY_e {
    display: grid;
    grid-template:
        ". . ." 1fr
        ". coverA ." auto
        ". . ." 7rem
        ". textA ." auto
        ". . ." 4.735rem
        ". soundOnA ." auto
        ". . ." 1fr / 1fr auto 1fr;
    width: 100vw;
    height: 100vh;
    justify-items: center;
}

.MePage_cover__1-VIS {
    grid-area: coverA;
    -webkit-animation: MePage_animation__24nB0 5s infinite;
            animation: MePage_animation__24nB0 5s infinite;
}

.MePage_soundOn__2uq3H {
    grid-area: soundOnA;
    text-align: center;
    font-size: 1rem;
}

@-webkit-keyframes MePage_animation__24nB0 {
    0%,
    20%,
    40%,
    50% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(30deg);
    }
    30% {
        transform: rotate(-30deg);
    }
    75% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes MePage_animation__24nB0 {
    0%,
    20%,
    40%,
    50% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(30deg);
    }
    30% {
        transform: rotate(-30deg);
    }
    75% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.MePage_text__Aa1Rs {
    grid-area: textA;
    max-width: 15rem;
    text-align: center;
    font-size: 1rem;
}

@media (min-width: 600px) {
    .MePage_text__Aa1Rs {
        max-width: none;
    }
}

.VersePage_gridcontainer__13DMP {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template:
        ". . ." 1fr
        ". mediumA ." auto
        ". . . " 1.75rem
        ". bigA ." auto
        ". . ." 3.56rem
        ". smallA ." auto
        ". . ." 1fr/1fr 18.75rem 1fr;
}

.VersePage_medium__1t8hf {
    grid-area: mediumA;
    font-size: 0.75rem;
}

.VersePage_big__3K3e2 {
    grid-area: bigA;
    font-size: 1.125rem;
}

.VersePage_small__HmLk9 {
    grid-area: smallA;
    font-size: 0.75rem;
    color: #943131;
}


.gridcontainer {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template:
        ". . ." 1fr
        ". mediumA ." auto
        ". . . " 1.75rem
        ". bigA ." auto
        ". . ." 3.56rem
        ". smallA ." auto
        ". . ." 1fr/1fr 18.75rem 1fr;
}

.medium {
    grid-area: mediumA;
    font-size: 0.75rem;
}

.big {
    grid-area: bigA;
    font-size: 1.125rem;
}

.small {
    grid-area: smallA;
    font-size: 0.75rem;
    color: #943131;
}

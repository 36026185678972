@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");

* {
    margin: 0;
    padding: 0;
}

html {
    font-family: "Montserrat", sans-serif;
    background: #ded9ff;
    color: #4e4c5b;
    line-height: 240%;
    letter-spacing: 0.15em;
    overflow-x: hidden;
}
